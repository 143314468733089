import axios from 'axios'

export default {
    getMainRaffle: () => axios.get('raffles/info'),
    getMainTickets: () => axios.get('raffles/tickets'),
    selectTickets: (code, data) => axios.post(''+code+'/ticket-reservation', data),
    getRaffle: (code) => axios.get(''+code+'/raffle-info'),
    getTickets: (code) => axios.get(''+code+'/available-tickets'),

    get: () => axios.get('raffles'),
    store: (data) => axios.post('raffles/store', data),
    update: (data) => axios.post('raffles/update', data),
    delete: (data) => axios.post('raffles/delete', data),
    active: (data) => axios.post('raffles/active', data),
    deactive: (data) => axios.post('raffles/deactive', data),

    checkWhatsapp: (number) => axios.get('raffles/'+number+'/whatsapp'),
    getReservations: (code) => axios.get('raffle/'+code+'/reservations'),
    getGamblers: (code) => axios.get('raffle/'+code+'/gamblers'),

    cancelReservation: (code, data) => axios.post('raffle/'+code+'/cancel-reservation', data),
    confirmReservation: (code, data) => axios.post('raffle/'+code+'/confirm-reservation', data),
    deleteReservation: (code, data) => axios.post('raffle/'+code+'/delete-reservation', data),

    searchingReservations: (code, data) => axios.post('raffle/'+code+'/search-reservations', data),
    sendWhatsapp: (code, data) => axios.post('raffle/'+code+'/send-reservation', data),
}